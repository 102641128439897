(function() {
    'use strict';

    angular.module('ionicLeaderboard', [
        'ionic',
        'ionicAlert',
        'ionicComment',
        'ionicData',
        'ionicForceRefresh',
        'ionicFriend',
        'ionicLogin',
        'ionicRequest',
        'uabDefaultImage',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabMoment',
        'uabPromiseManagement',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicLeaderboard').service(
        'LeaderboardService',
        LeaderboardService
    );

    LeaderboardService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'LeaderboardTypeService',
        'MomentService',
        'PromiseManagementService',
        'RequestManagementService'
    ];

    function LeaderboardService(
        DataManagementService,
        DefaultVariableService,
        LeaderboardTypeService,
        MomentService,
        PromiseManagementService,
        RequestManagementService
    ) {
        var LeaderboardService = this;

        LeaderboardService.getLeaderboard = getLeaderboard;
        function getLeaderboard(leaderboardType) {
            return LeaderboardTypeService.findLeaderboardType(leaderboardType).then(
                function(leaderboardType) {
                    if (leaderboardType) {
                        if (DefaultVariableService.isDefined(LeaderboardService.leaderboards[leaderboardType.type])) {
                            return PromiseManagementService.generateSuccess(
                                LeaderboardService.leaderboards[leaderboardType.type]
                            );
                        } else {
                            return LeaderboardService.loadLeaderboard(leaderboardType);
                        }
                    }

                    return false;
                }
            );
        }

        LeaderboardService.loadLeaderboard = loadLeaderboard;
        function loadLeaderboard(leaderboardType) {
            var date = MomentService().day(1);
            date = MomentService.toDateOnlyFormat(date);

            var subAction = leaderboardType.column + '/' + date;

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'leaderboard');
            options = RequestManagementService.setAction(options, 'get', subAction);

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        var leaderboard = data.leaderboard;

                        leaderboard.leaderboard_type = leaderboardType;

                        LeaderboardService.leaderboards[leaderboardType.type] = leaderboard;

                        return leaderboard;
                    }

                    return false;
                }
            );
        }

        LeaderboardService.loadLeaderboardTypes = loadLeaderboardTypes;
        function loadLeaderboardTypes() {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'leaderboard_types');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setOrder(
                options,
                [
                    'type'
                ]
            );

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        LeaderboardService.leaderboardTypes = data.leaderboard_types;

                        return LeaderboardService.leaderboardTypes;
                    }

                    return false;
                }
            );
        }

        LeaderboardService.reset = reset;
        function reset() {
            LeaderboardService.leaderboard = DefaultVariableService.getObject();
            LeaderboardService.leaderboard.leaderboard_type = DefaultVariableService.getObject();

            LeaderboardService.leaderboards = {};

            LeaderboardService.leaderboardType = DefaultVariableService.getObject();

            LeaderboardService.leaderboardTypes = [];
        }

        LeaderboardService.reset();

        return LeaderboardService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicLeaderboard').service('LeaderboardTypeService', LeaderboardTypeService);

    LeaderboardTypeService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'EnvironmentService',
        'PromiseManagementService',
        'RequestManagementService'
    ];

    function LeaderboardTypeService(
        DataManagementService,
        DefaultVariableService,
        EnvironmentService,
        PromiseManagementService,
        RequestManagementService
    ) {
        var LeaderboardTypeService = this;

        LeaderboardTypeService.findLeaderboardType = findLeaderboardType;
        function findLeaderboardType(leaderboardType) {
            if (DefaultVariableService.isDefined(leaderboardType)) {
                leaderboardType = DefaultVariableService.getString(
                    leaderboardType.column
                );
            }
            return LeaderboardTypeService.getLeaderboardTypes().then(
                function(leaderboardTypes) {
                    if (leaderboardTypes) {
                        var defaultLeaderboard = DefaultVariableService.get(
                            leaderboardType,
                            EnvironmentService.get('defaultLeaderboard')
                        );

                        if (defaultLeaderboard) {
                            var leaderboardTypesLength = leaderboardTypes.length;
                            for (var i = 0; i < leaderboardTypesLength; i++) {
                                if (leaderboardTypes[i].column === defaultLeaderboard) {
                                    return leaderboardTypes[i];
                                }
                            }
                        }

                        return leaderboardTypes[0];
                    }

                    return false;
                }
            );
        }

        LeaderboardTypeService.getLeaderboardType = getLeaderboardType;
        function getLeaderboardType(leaderboardType) {
            if (DefaultVariableService.isObject(leaderboardType)) {
                return LeaderboardTypeService.findLeaderboardType(leaderboardType);
            } else {
                return LeaderboardTypeService.getDefaultLeaderboardType();
            }
        }

        LeaderboardTypeService.getLeaderboardTypes = getLeaderboardTypes;
        function getLeaderboardTypes() {
            if (LeaderboardTypeService.leaderboardTypes.length === 0) {
                return LeaderboardTypeService.loadLeaderboardTypes();
            } else {
                return PromiseManagementService.generateSuccess(
                    LeaderboardTypeService.leaderboardTypes
                );
            }
        }

        LeaderboardTypeService.loadLeaderboardTypes = loadLeaderboardTypes;
        function loadLeaderboardTypes() {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'leaderboard_types');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setOrder(
                options,
                [
                    'type'
                ]
            );

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        LeaderboardTypeService.leaderboardTypes = DefaultVariableService.getArray(
                            data.leaderboard_types
                        );

                        return LeaderboardTypeService.leaderboardTypes;
                    }

                    return false;
                }
            );
        }

        LeaderboardTypeService.reset = reset;
        function reset() {
            LeaderboardTypeService.leaderboardTypes = [];
        }

        LeaderboardTypeService.reset();

        return LeaderboardTypeService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicLeaderboard').controller(
        'LeaderboardController',
        LeaderboardController
    );

    LeaderboardController.$inject = [
        'DefaultImageService',
        'DefaultVariableService',
        'EnvironmentService',
        'LeaderboardService',
        'LoginService',
        'RedirectService',
        '$scope'
    ];

    function LeaderboardController(
        DefaultImageService,
        DefaultVariableService,
        EnvironmentService,
        LeaderboardService,
        LoginService,
        RedirectService,
        $scope
    ) {
        var LeaderboardController = this;

        $scope.$watch(
            'showComments',
            function(showComments) {
                LeaderboardController.showComments = DefaultVariableService.get(
                    showComments,
                    true
                );
            }
        );

        LeaderboardController.clear = clear;
        function clear() {
            LeaderboardService.reset();
            LeaderboardController.reset();
        }

        LeaderboardController.forceRefresh = forceRefresh;
        function forceRefresh() {
            LeaderboardController.clear();
            LeaderboardController.init();
        }

        LeaderboardController.findLeaderboardType = findLeaderboardType;
        function findLeaderboardType(column) {
            var types = [
                { column: 'steps', type: 'Steps', unit: 'steps' },
                { column: 'distance', type: 'Distance', unit: 'miles' }
            ];

            var typeCount = types.length;

            for (var i = 0; i < typeCount; i++) {
                 if (types[i].column === column.toLowerCase()) {
                     return types[i];
                 }
            }

            return false;
        }

        LeaderboardController.getUserImage = getUserImage;
        function getUserImage(user) {
            return DefaultImageService.getUserImage(user);
        }

        LeaderboardController.loadLeaderboard = loadLeaderboard;
        function loadLeaderboard(leaderboardType) {
            LeaderboardController.isLoadingLeaderboard = true;

            return LeaderboardService.getLeaderboard(leaderboardType).then(
                function(leaderboard) {
                    if (leaderboard) {
                        LeaderboardController.leaderboard = leaderboard;
                    }

                    return leaderboard;
                }
            ).finally(
                function() {
                    LeaderboardController.isLoadingLeaderboard = false;
                }
            );
        }

        LeaderboardController.selectLeaderboardType = selectLeaderboardType;
        function selectLeaderboardType(leaderboardType) {
            if (DefaultVariableService.isString(leaderboardType)) {
                leaderboardType = LeaderboardController.findLeaderboardType(leaderboardType);
            }

            if (leaderboardType) {
                RedirectService.setUrl(
                    {
                        type: leaderboardType.column
                    }
                );

                return LeaderboardController.loadLeaderboard(leaderboardType);
            }

            return false;
        }

        LeaderboardController.reset = reset;
        function reset() {
            LeaderboardController.isLoadingLeaderboard = true;

            LeaderboardController.userSref = DefaultVariableService.get(
                LeaderboardController.userSref,
                'app.profile'
            );
        }

        LeaderboardController.selectUser = selectUser;
        function selectUser(user) {
            RedirectService.goTo(
                LeaderboardController.userSref,
                {
                    userId: user.id
                }
            );
        }

        LeaderboardController.init = init;
        function init() {
            LeaderboardController.reset();

            var leaderboardType = EnvironmentService.get('type', false);
            if (leaderboardType) {
                LeaderboardController.selectLeaderboardType(leaderboardType);
            } else {
                LeaderboardController.loadLeaderboard();
            }
        }

        LoginService.register(LeaderboardController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicLeaderboard').directive(
        'ionicLeaderboard',
        ionicLeaderboard
    );

    function ionicLeaderboard() {
        return {
            bindToController: {
                userSref: '@'
            },
            controller:   'LeaderboardController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {
                showComments: '='
            },
            template:'<div data-ng-if="!ctrl.isLoadingLeaderboard"><div class="row"><button class="col-6 button icon-left" data-ng-click="ctrl.selectLeaderboardType({ column: \'steps\', type: \'Steps\', unit: \'steps\' })"><i class="icon ion-android-walk"></i> STEPS/PUSHES</button> <button class="col-6 button icon-left" data-ng-click="ctrl.selectLeaderboardType({ column: \'distance\', type: \'Distance\', unit: \'miles\' })"><i class="icon ion-speedometer"></i> DISTANCE</button></div><ion-list data-ng-if="!ctrl.isLoadingLeaderboard || ctrl.leaderboard.id === 0 || ctrl.leaderboard.length === 0"><ionic-force-refresh refresh-function="ctrl.forceRefresh"></ionic-force-refresh><div><a class="item item-thumbnail-left" data-ng-repeat="position in ctrl.leaderboard.positions" data-ng-click="ctrl.selectUser(position.user)"><img data-ng-src="{{ ctrl.getUserImage(position.user) }}"><h2>{{ position.user.username }}</h2><p>{{ position.total }} {{ ctrl.leaderboard.leaderboard_type.unit | uppercase }}</p><span class="badge badge-assertive badge">#{{ position.position }}</span></a><ionic-friend-button user="position.user"></ionic-friend-button></div><div class="bar bar-stable" data-ng-if="ctrl.leaderboard.positions.length === 0 && !ctrl.isLoadingLeaderboard"><h1 class="title">NO DATA</h1></div></ion-list><div data-ng-if="ctrl.showComments" class="item-body"><ionic-comment-list class="item-body" comments="ctrl.leaderboard.comments" parent-object="ctrl.leaderboard.leaderboard_type" parent-type="leaderboard_types" show-comment-list="true" show-new-comment="true" user-sref="app.profile"></ionic-comment-list></div></div><span data-ng-if="ctrl.isLoadingLeaderboard || ctrl.leaderboard.id === 0"><ion-spinner class="centerSpinner"></ion-spinner></span>'
        };
    }
})();